
import { defineComponent, ref, onBeforeMount, computed } from 'vue'
import { getNoticeList } from '@/api/notice'
import { ERR_OK } from '@/api/config'
import { NoticeConfig } from '@/types'
// import { useMessage } from '@/hooks/core/useMessage'
const tabList = ['实战', '系统']
export default defineComponent({
  name: 'Notice',
  setup () {
    // const message = useMessage()
    const activeIndex = ref(0)
    const noticeList = ref<NoticeConfig[]>([])
    const currentCode = computed(() => {
      return activeIndex.value === 0 ? 1 : 2
    })
    const filterList = computed(() => {
      return noticeList.value.filter(item => item.code === currentCode.value)
    })
    const total = computed(() => {
      const ret = [0, 0]
      noticeList.value.forEach(item => {
        ret[item.code - 1]++
      })
      return ret
    })
    const handleReadAll = () => {
      noticeList.value = noticeList.value.map(item => {
        return {
          ...item,
          isRead: item.code === currentCode.value ? true : item.isRead
        }
      })
      // message.success('标记成功')
    }
    onBeforeMount(async () => {
      const { code, data } = await getNoticeList<NoticeConfig>()
      if (code === ERR_OK) {
        noticeList.value = data.list
      }
    })
    return {
      activeIndex,
      tabList,
      filterList,
      total,
      handleReadAll
    }
  }
})
